<template>
  <div class="banks-list-item">
    <div class="banks-list-item__name">
      <img :src="bankData.icon_path" alt="">
      <span>{{ bankData.name }}</span>
    </div>
    <StatsInfoChart :chartData="statsChartData" />
  </div>
</template>

<script>
import StatsInfoChart from "@/buying-teams/shared-components/utils/StatsInfoChart";
import { numberDecimalsFormat } from "@/core/helpers/GlobalHelper";

export default {
  name: "BusinessFeedbacksBanksListItem",
  components: {StatsInfoChart},
  props: {
    bankData: Object
  },
  computed: {
    statsChartData() {
      return [
        {
          name: 'Score',
          value: numberDecimalsFormat(this.bankData.stats.score),
          className: 'score',
          showRangeColor: true
        },
        {
          name: 'Importance',
          value: numberDecimalsFormat(this.bankData.stats.importance)
        },
        {
          name: 'Gap',
          value: numberDecimalsFormat(this.bankData.stats.gap),
          className: 'gap',
          showRangeColor: true,
          isForGap: true
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.banks-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border-radius: 5.28234px;
  height: 67px;
  padding-left: 24px;
  padding-right: 24px;

  &__name {
    img {
      width: 34px;
      height: 34px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      border: 1px solid #f2f2f2;
      margin-right: 8px;
    }
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 158.02%;
      color: #2B2B2B;
    }
  }
  .stats-info-chart {
    gap: 29px;
    margin-top: 18px;
    margin-right: 0;
    border-bottom: 1px solid rgba(224, 224, 224, 0.5);
  }
}
</style>
