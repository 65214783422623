<template>
    <CustomLoader v-if="loading" />
    <div v-else-if="!loading && dedicatedFeedbackData" class="business-single-feedback">
        <div class="business-single-feedback__link">
            <a href="" @click.prevent="backToQuestion">
                <img src="/media/buying/icons/arrow-left.svg" alt="">
                {{ $t("Back to Feedback Sessions") }}
            </a>
        </div>
        <div class="custom_row">
            <div class="custom_row--col-big">
                <BusinessFeedbackDedicatedItem :feedback="dedicatedFeedbackData.survey" />
            </div>
            <div class="custom_row--col-small">
                <div class="business-single-feedback__chart">
                    <div class="chart-title">
                        {{ $t("Feedback Session Score") }}
                    </div>
                    <CircleChart
                        v-if="showChart"
                        :chartData="chartData"
                        :values="[chartData.score, chartData.importance]"
                        :chartConfigs="{
                            width: 300,
                            height: 220,
                            valueVisible: false
                        }"
                    />

                    <StatsInfoChart :chartData="statsChartData" />
                </div>
            </div>
        </div>

        <BusinessFeedbacksBanksList
            :banksData="dedicatedFeedbackData.survey.banks_data"
        />

        <QuestionsTabsContainer
            :benchmark-questions-list="dedicatedFeedbackData.allQuestions"
            :custom-questions="dedicatedFeedbackData.customQuestionsSectionData"
            @onSortingChange="dedicatedFeedbackData.sortQuestionsBy($event)"
            :isSurveyQuestion="true"
            @onClickQuestionItem="handleClickQuestionItem"
        />
    </div>
</template>
<script>
import BusinessFeedbackDedicatedItem from "@/buying-teams/pages/business/feedbacks/BusinessFeedbackDedicatedItem";
import store from "@/store";
import CustomLoader from "../../../shared-components/utils/CustomLoader";
import CircleChartModel from "@/store/models/CircleChartModel";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CircleChart from "@/buying-teams/shared-components/charts/CircleChart";
import BusinessFeedbacksBanksList from "./components/BusinessFeedbacksBanksList";
import StatsInfoChart from "@/buying-teams/shared-components/utils/StatsInfoChart";
import { DedicatedFeedbackContainer } from "@/store/models/business/feedback/DedicatedFeedbackContainer";
import moment from "moment";
import { numberDecimalsFormat } from "@/core/helpers/GlobalHelper";
import QuestionsTabsContainer from "@/buying-teams/shared-components/questions/QuestionsTabsContainer";

export default {
    name: "BusinessSingleFeedback",
    components: {
        QuestionsTabsContainer,
        StatsInfoChart,
        BusinessFeedbacksBanksList,
        CircleChart,
        BusinessFeedbackDedicatedItem,
        CustomLoader
    },

    computed: {
        chartData() {
            return new CircleChartModel({
                score: this.dedicatedFeedbackData.score,
                importance: this.dedicatedFeedbackData.importance
            });
        },

        statsChartData() {
            return [
                {
                    name: "Gap",
                    value: numberDecimalsFormat(this.chartData.gap),
                    className: "gap",
                    showRangeColor: true,
                    isForGap: true
                },
                {
                    name: "Respondents",
                    value: this.dedicatedFeedbackData.respondents
                },
                {
                    name: "Scores",
                    value: this.dedicatedFeedbackData.scores
                }
            ];
        },

        businessBaseFilterDefaultState() {
            return store.getters.businessBaseFilterDefaultState;
        }
    },

    data() {
        return {
            dedicatedFeedbackData: null,
            showChart: false,
            tableData: [],
            loading: true,
            numberDecimalsFormat
        };
    },

    async mounted() {
        setCurrentPageBreadcrumbs({
            title: this.$t("Feedback Session"),
            subTitle: this.$t("Score or review your Banks' performance")
        });
        await this.getSurveyStats();
        setCurrentPageBreadcrumbs({
            title: `${this.$t("Feedback Session")} <span class="feedbacks_id">#${this.dedicatedFeedbackData.survey.id}</span>`,
            subTitle: this.dedicatedFeedbackData.survey.status.statusName ? `<div class="feedbacks_time">
          <span class="${this.dedicatedFeedbackData.survey.status.statusClass}">${this.$t(this.dedicatedFeedbackData.survey.status.statusName)} </span>
          - ${moment(this.dedicatedFeedbackData.survey.session_start).format("DD.MM.YYYY")}
          - ${moment(this.dedicatedFeedbackData.survey.session_end).format("DD.MM.YYYY")}
        </div>` : this.$t("Score or review your Banks' performance")
        });
    },

    methods: {
        async getSurveyStats() {
            await store.dispatch("getSurveyStats", this.$route.params.id)
                .then(res => {
                    this.dedicatedFeedbackData = new DedicatedFeedbackContainer(res);
                    this.showChart = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        handleClickQuestionItem(question, newTab) {
            let filter = {
                bank_ids: this.businessBaseFilterDefaultState.bank_ids,
                countries: this.businessBaseFilterDefaultState.countries,
                survey_ids: [this.dedicatedFeedbackData.survey.id],
                time_period: this.businessBaseFilterDefaultState.time_period,
            };
            store.commit("SAVE_BUSINESS_SINGLE_QUESTION_FILTER", filter);
            const questionUrl = `/business/questions/${question.id}${question.isCustom ? '?isCustom=true' : ''}`;

            if (newTab) {
                window.open(questionUrl, '_blank');
            } else {
                this.$router.push(questionUrl);
            }
        },

        backToQuestion() {
            this.$router.push("/business/feedback/feedback-sessions");
        }
    }
};
</script>
<style lang="scss">
#kt_header_menu_nav {
    h1 {
        display: flex;
        align-items: center;

        span.feedbacks_id {
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.21);
            border-radius: 3.6px;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #000000;
            padding: 5px;
            margin-left: 16px;
        }
    }

    .subTitle .feedbacks_time {
        span {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;

            &.live {
                color: #000000;
            }

            &.secondary {
                color: #9CA0AB;
            }

            &.primary {
                color: #435BF4;
            }

            &.danger {
                color: #E22D21;
            }

            &.success {
                color: #16D278;
            }
        }
    }
}

.business-single-feedback {
    .feedbackItem {
        min-height: calc(100% - 36px);
    }

    .tooltipMore {
        background: #F8F8F8;
        box-shadow: 0 5px 9px rgb(0 0 0 / 16%);
        border-radius: 6px;
        padding: 10px;
        position: absolute;
        z-index: 9;
        right: 0;
        top: 20px;
        display: none;

        span {
            background: transparent;
        }
    }

    .show-countries {
        position: relative;
        cursor: pointer;

        &:hover .tooltipMore {
            display: grid;
        }
    }

    .comment-box {
        input {
            height: 50px;
            background: #F8F8F8;
            box-shadow: inset 0 1.12463px 4.4985px rgba(0, 0, 0, 0.05);
            border-radius: 7px;
        }
    }

    .gray-label {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;

        &__item {
            background: #F8F8F8;
            border-radius: 5px;
            padding: 8px 6px;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
            cursor: default;

            &--tooltip {
                background: #F8F8F8 !important;
                box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.16);
                border-radius: 6px;
                border: none !important;
                display: flex;
                flex-direction: column;
                padding-bottom: 3px;

                span {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                    margin-bottom: 7px;
                }
            }
        }
    }

    .custom_row {
        display: flex;
        margin-top: 14px;
        gap: 16px;

        &--col {
            &-small {
                width: 335px;
            }

            &-big {
                width: calc(100% - 351px);
            }
        }
    }

    &__link {
        padding-left: 10px;

        a {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #435BF4;
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }

    &__chart {
        background: #FFFFFF;
        box-shadow: 0px 9px 29px rgba(0, 0, 0, 0.06);
        border-radius: 12px;
        padding: 23px 18px 0 18px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .chart-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 13px;
            color: #000000;
            opacity: 0.87;
        }
    }

    @media (max-width: 1400px) {
        .custom_row {
            &--col {
                &-small {
                    .card {
                        height: 100%;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .custom_row {
            flex-direction: column;

            &--col {
                &-small {
                    width: 100%;
                    margin-top: 0;

                    .gray-label {
                        flex-wrap: nowrap;
                        overflow-x: auto;
                        max-width: 100%;

                        &__item {
                            white-space: pre;
                        }
                    }
                }

                &-big {
                    width: 100%;
                }
            }
        }
    }
}
</style>
