import { SurveyModel } from "@/store/models/SurveyModel";
import { QuestionModel } from "@/store/models/bank/CompareProductAreaModel";
import {
    CustomQuestionsAnalyticsContainer
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsContainer";

export class DedicatedFeedbackContainer {
    survey: SurveyModel;
    allQuestions: QuestionModel[];
    importance: string;
    score: string;
    respondents: string;
    scores: string;
    customQuestionsSectionData: CustomQuestionsAnalyticsContainer = new CustomQuestionsAnalyticsContainer();

    constructor(obj) {
        this.survey = new SurveyModel(obj["survey"]);
        this.allQuestions = obj.questions ? obj.questions.map(q => new QuestionModel({
            ...q,
            ...q.stats
        })) : [];
        this.importance = obj["importance"];
        this.score = obj["score"];
        this.respondents = obj["respondents"];
        this.scores = obj["scores"];

        if (obj["customQuestions"]) {
            this.customQuestionsSectionData.setCustomQuestionsAnalyticsData(obj["customQuestions"]);
        }
    }

    async sortQuestionsBy(what: string | null): Promise<QuestionModel[]> {
        switch (what) {
            case "highest_score":
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.score) - parseFloat(q1.score));
                break;
            case "lowest_score":
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
                break;
            case "highest_importance_score":
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.importance) - parseFloat(q1.importance));
                break;
            case "lowest_importance_score":
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.importance) - parseFloat(q2.importance));
                break;
            case "highest_negative_gap":
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.gap) - parseFloat(q2.gap));
                break;
            case "highest_positive_gap":
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.gap) - parseFloat(q1.gap));
                break;
            case "highest_standard_deviation":
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
                break;
            case "lowest_standard_deviation":
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
                break;
        }
        return this.allQuestions;
    }
}
