<template>
  <div class="dedicated-feedback-item">
    <div class="dedicated-feedback-item__header">
      <div class="dedicated-feedback-item__header--left">
        <div class="header-bar">{{ feedback.phases.join(', ') }}</div>
        <div class="header-bar">
          {{ $t('Start Date') }}
          <div v-html="dateFormatInTimeZone(feedback.session_start)" class="date-time-zone"></div>
        </div>
        <div class="header-bar">
          {{ $t('End Date') }}
          <div v-html="dateFormatInTimeZone(feedback.session_end)" class="date-time-zone"></div>
        </div>
      </div>
      <div class="dedicated-feedback-item__header--right">
        <div
          v-if="feedback.status.statusName === SurveyStatuses.LIVE"
          class="d-flex align-items-center justify-content-end"
        >
          <img width="18" src="/media/buying/icons/liveIcon.svg" alt="">
          <p class="fw-bolder m-2">{{ $t(SurveyStatuses.LIVE) }}</p>
        </div>
        <StatusComponent
          v-else
          :text="feedback.status.statusName"
          :type="feedback.status.statusClass"
        />
      </div>
    </div>
    <div class="dedicated-feedback-item__body">
      <div class="dedicated-feedback-title">
        {{ feedback.title }}
      </div>
      <div class="dedicated-feedback-description">
        {{ feedback.description }}
      </div>
      <div class="feedback-info mb-5">
        <div class="feedback-info__title">{{ $t('Product Areas') }}</div>
        <div class="feedback-info__value">
          <div
            class="feedback-info__value--item"
            v-for="(area, index) in feedback.product_areas"
            :key="index"
          >{{ area }}</div>
        </div>
      </div>
      <div class="feedback-info">
        <div class="feedback-info__title">{{ $t('Countries') }}</div>
        <div class="feedback-info__value">
          <div
              class="feedback-info__value--item"
              v-for="(country, index) in feedback.countries"
              :key="index"
          >{{ country }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {SurveyStatuses} from "@/store/models/SurveyModel";
import StatusComponent from "@/buying-teams/shared-components/utils/StatusComponent";
import {dateFormatInTimeZone} from "@/core/helpers/GlobalHelper";

export default {
  name: "BusinessFeedbackDedicatedItem",
  components: {StatusComponent},
  props: {
    feedback: Object,
  },
  data() {
    return {
      SurveyStatuses,
      dateFormatInTimeZone,
    }
  },
};
</script>
<style lang="scss" scoped>
.dedicated-feedback-item {
  background: #fff;
  box-shadow: 0px 6px 29px rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  overflow: hidden;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 10px 10px;
    background: #FAFAFA;
    &--left {
      display: flex;
      align-items: center;
      width: 100%;
      overflow-x: auto;
      padding-bottom: 5px;
      margin-bottom: -5px;
      > * {
        white-space: nowrap;
      }
      .header-bar {
        padding: 0 10px;
        font-weight: 400;
        font-size: 11px;
        line-height: 158.02%;
        color: #969696;

        span {
          color: #000000;
        }

        &:not(:last-child) {
          border-right: 1px solid #969696;
        }
        &:nth-child(1) {
          color: #030303;
        }
      }
    }
    &--right {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-left: 10px;
      > * {
        white-space: nowrap;
      }
      .invitees-section {
        font-weight: 400;
        font-size: 11px;
        line-height: 158.02%;
        color: #969696;
        span {
          font-size: 16px;
          color: #000000;
          font-weight: 700;
        }
      }
    }
  }
  &__body {
    padding: 12px 29px 18px 23px;
    .dedicated-feedback-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 158.02%;
      color: #2B2B2B;
      margin-bottom: 6px;
    }
    .dedicated-feedback-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 158.02%;
      color: #595959;
      padding-bottom: 16px;
      border-bottom: 1px solid #E0E0E0;
      margin-bottom: 18px;
    }
    .feedback-info {
      &__title {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #696969;
        margin-bottom: 8px;
      }
      &__value {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        &--item {
          background: #F6F6F6;
          border-radius: 5px;
          padding: 8px 7px;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
        }
      }
    }
  }
}
</style>
