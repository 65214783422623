<template>
  <div class="bf-bank-list">
    <div class="bf-bank-list__header">
      <div class="bf-bank-list__header--title">
        <h4>{{ $t('Banks in Session') }}</h4>
        <span>{{ banksData.length }}</span>
      </div>
      <div class="bf-bank-list__header--filter">
        <div class="filter-badge" :class="{'show': getFilterBadgeCount}">{{ getFilterBadgeCount }}</div>
<!--        <el-form>-->
<!--          <div-->
<!--            class="filter-item"-->
<!--            :class="{-->
<!--              'is-all-selected': filterData.product_areas.length > 0-->
<!--            }"-->
<!--          >-->
<!--            <el-form-item :label="$t('Product Areas')">-->
<!--              <div class="cursor-pointer position-relative z-index-1">-->
<!--                <div class="is-all-selected__text">-->
<!--                  {{ filterData.product_areas.length === productAreas.length ? $t('All') : $t('Select More') }}-->
<!--                </div>-->
<!--                <el-select-->
<!--                  multiple-->
<!--                  v-model="filterData.product_areas"-->
<!--                  placeholder="Select"-->
<!--                >-->
<!--                  <el-option-->
<!--                    v-for="item in productAreas"-->
<!--                    :key="item"-->
<!--                    :label="item"-->
<!--                    :value="item"-->
<!--                  />-->
<!--                </el-select>-->
<!--              </div>-->
<!--            </el-form-item>-->
<!--          </div>-->
<!--          <div-->
<!--            class="filter-item"-->
<!--            :class="{-->
<!--              'is-all-selected': filterData.countries.length > 0-->
<!--            }"-->
<!--          >-->
<!--            <el-form-item :label="$t('Countries')">-->
<!--              <div class="cursor-pointer position-relative z-index-1">-->
<!--                <div class="is-all-selected__text">-->
<!--                  {{ filterData.countries.length === businessCountries.length ? $t('All') : $t('Select More') }}-->
<!--                </div>-->
<!--                <el-select-->
<!--                  multiple-->
<!--                  v-model="filterData.countries"-->
<!--                  placeholder="Select"-->
<!--                >-->
<!--                  <el-option-->
<!--                    v-for="item in businessCountries"-->
<!--                    :key="item"-->
<!--                    :label="item"-->
<!--                    :value="item"-->
<!--                  />-->
<!--                </el-select>-->
<!--              </div>-->
<!--            </el-form-item>-->
<!--          </div>-->
<!--        </el-form>-->
      </div>
    </div>

    <div class="bf-bank-list__body">
      <BusinessFeedbacksBanksListItem
        v-for="(bank, index) in getFilteredData"
        :key="index"
        :bankData="bank"
      />
      <div class="no-data-found" v-if="!getFilteredData.length">
        {{ $t('No banks found') }}
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import BusinessFeedbacksBanksListItem
  from "@/buying-teams/pages/business/feedbacks/components/BusinessFeedbacksBanksListItem";

export default {
  name: "BusinessFeedbacksBanksList",
  components: {BusinessFeedbacksBanksListItem},
  props: {
    banksData: Object
  },
  data() {
    return {
      value: '',
      filterData: {
        product_areas: [],
        countries: []
      },
    }
  },
  computed: {
    productAreas() {
      return store.getters.businessProductAreas;
    },
    businessCountries() {
      return store.getters.businessCountries;
    },
    getFilterBadgeCount() {
      let countries = this.filterData.countries.length === this.businessCountries.length || !this.filterData.countries.length ? 0 : 1;
      let product_areas = this.filterData.product_areas.length === this.productAreas.length || !this.filterData.product_areas.length ? 0 : 1;
      return countries + product_areas
    },
    getFilteredData() {
      const filtered = this.banksData.filter((el) => {
        return this.filterData.countries.some((f) => {
          return el.countries.includes(f);
        });
      });

      return this.filterData.countries.length ? filtered : this.banksData;
    }
  },
  methods: {
    idSelectedAll(item, listName) {
      return this.filterData[item].length === this[listName].length && this[listName].length !== 0
    },
  }
}
</script>

<style lang="scss">
.bf-bank-list {
  background: #FFFFFF;
  box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  margin-top: 14px;
  margin-bottom: 16px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 0 9px 0;
    margin: 0 15px 0 23px;
    border-bottom: 1px solid rgba(224, 224, 224, 0.5);
    &--title {
      display: flex;
      align-items: center;
      h4 {
        font-weight: 700;
        font-size: 18px;
        line-height: 158.02%;
        color: #2B2B2B;
        margin-bottom: 0;
        margin-right: 7px;
      }
      span {
        background: #ECECEC;
        border-radius: 3px;
        padding: 2px 10px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
      }
    }
    &--filter {
      position: relative;
      .filter-badge {
        position: absolute;
        right: -7px;
        top: -6px;
        background: #E22D21;
        border: 1px solid #FFFFFF;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
        width: 21px;
        height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        z-index: 3;
        transform: scale(0);
        opacity: 0;
        visibility: hidden;
        transition: .3s ease-in-out;
        &.show {
          opacity: 1;
          visibility: visible;
          transform: scale(1);
        }
      }
      .el-form {
        display: flex;
        align-items: center;
        gap: 18px;
      }
      .el-form-item {
        margin-bottom: 0;
        .el-form-item__content {
          background: #F6F6F6;
          border-radius: 5.28234px;
        }
        .el-select__tags {
          display: none;
        }
        &__label {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #434343;
          opacity: 0.6;
          display: flex;
          align-items: center;
          padding-right: 16px;
        }
      }
      .el-select {
        z-index: 2;
        .el-input__inner {
          width: 100%;
          height: 44px !important;
          background: transparent;
        }
      }
    }
  }
  &__body {
    .banks-list-item:nth-child(even) {
      background: #FAFAFA
    }
    .no-data-found {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0;
    }
  }
}
</style>
